import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "keyboard-accessibility"
    }}>{`Keyboard accessibility`}</h2>
    <p>{`Keyboard accessibility is helpful for users who rely on or prefer using a keyboard. Make sure to provide keyboard functionality to all interactive content. Common keyboard interactions include using the `}<inlineCode parentName="p">{`tab`}</inlineCode>{` key to select different interactive elements on a page and using the `}<inlineCode parentName="p">{`enter`}</inlineCode>{` key or the `}<inlineCode parentName="p">{`spacebar`}</inlineCode>{` to activate an in-focus element.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.ibm.com/able/guidelines/ci162/keyboard.html"
      }}>{`IBM Checkpoint 2.1.1 Keyboard`}</a></p>
    <h3 {...{
      "id": "focus-indicators"
    }}>{`Focus indicators`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`tab`}</inlineCode>{` key navigates through all interactive elements on a page in the order they appear in the HTML document. A default visual indicator is provided by the web browser in use. The display is a border around the focused element. When an element is in focus, it can be further activated using the keyboard.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.ibm.com/able/guidelines/ci162/focus_visible.html"
      }}>{`IBM Checkpoint 2.4.7 Focus Visible`}</a></p>
    <h3 {...{
      "id": "navigation-order"
    }}>{`Navigation order`}</h3>
    <p>{`The order in which interactive elements receive focus should be logical and predictable. Create the tab flow hierarchy by using the source code to arrange the keyboard navigation. A common flow might begin with the header, followed by the main navigation, then content navigation (from left to right, top to bottom), and end with the footer. Try to give all your users the same experience.`}</p>
    <p>{`Use natively-accessible elements in navigation to activate links, buttons, and form controls with a keyboard. Reinforce semantic HTML to convey intent and meaning instead of solely defining the look and feel of an element. Enhance with ARIA (Accessible Rich Internet Application) labels when necessary.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.ibm.com/able/guidelines/ci162/focus_order.html"
      }}>{`IBM Checkpoint 2.4.3 Focus Order`}</a></p>
    <h3 {...{
      "id": "landmarks"
    }}>{`Landmarks`}</h3>
    <p>{`Communicate to screen-reader users the different areas of the screen and what they do with landmarks and by using appropriate HTML5 labels. Screen reader users can then quickly jump to any area they want.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.ibm.com/able/guidelines/ci162/info_and_relationships.html"
      }}>{`IBM Checkpoint 1.3.1 Info and Relationships`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      